import React from 'react';
import { Helmet } from 'react-helmet';
import './PricingPage.css';
import { FaCheck } from 'react-icons/fa';
import InfoIcon from '@mui/icons-material/HelpOutline';
import { IconButton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
const featureTooltips = {
  '15 AI assistant responses per month': 'Receive 15 responses from your AI assistant each month on the free plan.',
  '1 AI assistant': 'You can create and manage 1 AI assistant with this plan.',
  '400,000 characters/AI assistant': 'The maximum character limit that can be processed per AI assistant (~5MB). ',
  '1 team member': 'Invite 1 team member to collaborate on your AI assistant.',
  '10 team members': 'Invite 10 team members to collaborate on your AI assistant.',
  'Unlimited links to train on': 'You can use as many links as you want to train your AI assistant.',
  'Embed on unlimited websites': 'Embed your AI assistant on any number of websites.',
  'View chat history': 'Access past interactions your AI assistant has had with users.',
  'GPT-4o-mini model': 'The AI assistant uses the GPT-4o-mini model for generating responses.',
  'AI assistants get deleted after 14 days of inactivity on the free plan.': 'Inactive AI assistants on the free plan are deleted after 14 days of no activity.',
  '500 AI assistant responses per month': 'Receive 500 responses from your AI assistant each month.',
  '5,000,000 characters/AI assistant': 'The maximum character limit that can be processed per AI assistant (~50MB).',
  '2 AI assistants': 'You can create and manage up to 2 AI assistants with this plan.',
  'Facebook and Instagram Messenger integration': 'Integrate your AI assistant with Facebook and Instagram Messenger.',
  '10,000 AI assistant responses/month': 'Receive 10,000 responses from your AI assistant each month.',
  '10 AI assistants': 'You can create and manage up to 10 AI assistants with this plan.',
  '11,000,000 characters/AI assistant': 'The maximum character limit that can be processed per AI assistant (~140MB).',
  'Premium GPT-4o models': 'Access both GPT-4o-mini and premium GPT-4o models.',
  'Eveything in Business + Advanced CRM & API Integrations': 'All Business plan features plus advanced CRM and API integrations.',
  'Custom AI assistant responses/month': 'The number of responses can be customised based on your needs.',
  'Prioritised Support': 'Get prioritised customer support for faster resolutions.',
  'Remove "Powered by AIssie"': 'Remove the "Powered by AIssie" branding from your AI assistant.'
};
const pricingPlans = [
  {
    type: 'Trial',
    price: '$0',
    duration: 'Forever',
    features: [
      '15 AI assistant responses per month',
      '1 AI assistant',
      '400,000 characters/AI assistant',
      '1 team member',
      'Unlimited links to train on',
      'Embed on unlimited websites',
      'View chat history',
      'GPT-4o-mini model',
      'AI assistants get deleted after 14 days of inactivity on the free plan.'
    ]
  },
  {
    type: 'Starter',
    price: '$59',
    duration: 'Per Month',
    mostPopular: true, // Added flag
    features: [
      'Everything from Trial plan + ',
      '500 AI assistant responses per month',
      '2 AI assistants',
      '5,000,000 characters/AI assistant',
      'Facebook and Instagram Messenger integration'
    ]
  },
  {
    type: 'Business',
    price: '$199',
    duration: 'Per Month',
    features: [
      'Everything from Starter plan + ',
      '10,000 AI assistant responses/month',
      '10 AI assistants',
      '11,000,000 characters/AI assistant',
      '10 team members',
      'Premium GPT-4o models',
    ]
  },
  {
    type: 'Enterprise',
    price: 'Custom Pricing',
    duration: 'Per Month',
    features: [
      'Eveything in Business + Advanced CRM & API Integrations',
      'Custom AI assistant responses/month',
      'Prioritised Support',
      'Remove "Powered by AIssie"',
    ]
  }
];

const PricingPage = () => {
  const navigate = useNavigate();
  const currentPage = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  const handleButtonClick = (planType) => {
    // Push the event to dataLayer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'subscribeButtonClick',
      eventCategory: 'Button',
      eventAction: 'Click',
      eventLabel: planType,
    });

    // Navigate to the appropriate page
    if (planType === 'Enterprise') {
      navigate('/contactus');
    } else {
      window.location.href = `https://app.aissie.com.au/signup?subscription=${planType.toLowerCase()}`;
    }
  };

  return (
    <div className="pricing-container">
      <Helmet>
        <title>AIssie - Pricing Plans</title>
        <meta
          name="description"
          content="Explore AIssie's pricing plans and choose the best option for your business. Start with our Trial plan or upgrade to access more features."
        />
        <link rel="canonical" href="https://www.aissie.com.au/pricing" />

        {/* Structured Data for Pricing Plans */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "ItemList",
            "itemListElement": [
              ${pricingPlans
            .map((plan, index) => {
              const position = index + 1;
              return `{
                    "@type": "Offer",
                    "position": ${position},
                    "name": "${plan.type} Plan",
                    "description": "${plan.features.join(' ')}",
                    "price": "${plan.price.replace('$', '')}",
                    "priceCurrency": "AUD",
                    "url": "https://www.aissie.com.au/pricing#${plan.type.toLowerCase()}",
                    "priceValidUntil": "2024-12-31"
                  }`;
            })
            .join(',')}
            ]
          }
        `}</script>
      </Helmet>
      {pricingPlans.map((plan) => (
        <div
          className={`pricingCard ${plan.mostPopular ? 'most-popular' : ''}`}
          key={plan.type}
          id={plan.type.toLowerCase()}
        >
          {plan.mostPopular && <div className="most-popular-label">Most Popular</div>}
          <h2 className="pricingCard-title">{plan.type}</h2>
          <p className="pricingCard-price">{plan.price}</p>
          <p className="pricingCard-duration">{plan.duration}</p>
          <ul className="pricingCard-features">
            {plan.features.map((feature, index) => (
              <li key={index} className="pricingCard-feature">
                <div className="feature-content">
                  <FaCheck className="check-icon" /> {feature}
                  <Tooltip title={featureTooltips[feature] || ''} placement="top" enterTouchDelay={0} leaveTouchDelay={5000} >
                    <IconButton size="small">
                      <InfoIcon style={{ color: 'rgba(0, 0, 0, 0.88)' }} />
                    </IconButton>
                  </Tooltip>
                </div>

              </li>
            ))}
          </ul>
          <button
            className="subscribe-button"
            onClick={() => handleButtonClick(plan.type)}
          >
            {plan.type === 'Enterprise' ? 'Contact Us' : 'Subscribe'}
          </button>
        </div>
      ))}
    </div>
  );
};

export default PricingPage;
