import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';

import './ContactUs.css';

const ContactForm = ({ handleChange, handleSubmit, formData }) => (
  <form onSubmit={handleSubmit} className="contact-form">
    <div className="form-input-group">
      <label htmlFor="name">
        Name <span className="required-marker">*</span>
      </label>

      <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} className="form-input" required />
    </div>
    <div className="form-input-group">
      <label htmlFor="email">Work Email <span className="required-marker">*</span></label>
      <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} className="form-input" required />
    </div>
    <div className="form-input-group">
      <label htmlFor="company">Company Name <span className="required-marker">*</span></label>
      <input type="text" id="company" name="company" value={formData.company} onChange={handleChange} className="form-input" required />
    </div>
    <div className="form-input-group">
      <label htmlFor="jobTitle">Job Title <span className="required-marker">*</span></label>
      <input type="text" id="jobTitle" name="jobTitle" value={formData.jobTitle} onChange={handleChange} className="form-input" required />
    </div>
    <div className="form-input-group">
      <label htmlFor="phone">Phone Number <span className="required-marker">*</span></label>
      <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange} className="form-input" required />
    </div>
    <div className="form-input-group">
      <label htmlFor="message">Message <span className="required-marker">*</span></label>
      <textarea id="message" name="message" value={formData.message} onChange={handleChange} className="form-input" rows="5" required></textarea>
    </div>
    <button type="submit" className="submit-button">Send</button>
  </form>
);

const ContactUs = () => {
  const initialFormData = { name: '', email: '', company: '', jobTitle: '', phone: '', message: '' };
  const [formData, setFormData] = useState(initialFormData);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const currentPage = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/send_email`, formData);
      setSuccessMessage(response.data.message);
      setFormData(initialFormData);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'contactFormSubmission',
        eventCategory: 'Form',
        eventAction: 'Submit',
        eventLabel: 'Contact Us',
        formData: {
          messageLength: formData.message.length,
        },
      });
    } catch (error) {
      setErrorMessage('Error sending email. Please try again later.');
      console.error('Error sending email:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="contact-container">
      <Helmet>
        <title>Contact Us - AIssie</title>
        <p>
          Fill the below form or call us at
          <a href="tel:0499712235">0499 712 235</a>
          for more information.
        </p>
        <meta
          name="description"
          content="Get in touch with AIssie to learn more about our AI assistant solutions and how we can help your business."
        />
        <link rel="canonical" href="https://www.aissie.com.au/contactus" />

        {/* Structured Data */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "ContactPage",
            "name": "Contact Us - AIssie",
            "description": "Get in touch with AIssie to learn more about our AI assistant solutions and how we can help your business.",
            "url": "https://www.aissie.com.au/contactus",
            "breadcrumb": {
              "@type": "BreadcrumbList",
              "itemListElement": [
                {
                  "@type": "ListItem",
                  "position": 1,
                  "name": "Home",
                  "item": "https://www.aissie.com.au"
                },
                {
                  "@type": "ListItem",
                  "position": 2,
                  "name": "Contact Us",
                  "item": "https://www.aissie.com.au/contactus"
                }
              ]
            }
          }
        `}</script>
      </Helmet>

      <h2 className="heading">Contact Us</h2>
      {isLoading && <div className="loading-spinner"></div>}
      {successMessage && <div className="alert-message success-message">{successMessage}</div>}
      {errorMessage && <div className="alert-message error-message">{errorMessage}</div>}
      <div className="form-container">
        <ContactForm handleChange={handleChange} handleSubmit={handleSubmit} formData={formData} />
      </div>
    </div>
  );
};

export default ContactUs;
